<template>
  <div>
    <PageTitle
      :title="$route.meta.title"
      btn="新增"
      @btnClick="$router.push({name: 'RewardActivityEdit'})"
    />
    <FilterContainer>
      <BaseElInput
        v-model="search.name"
        placeholder="輸入活動名稱"
        clearable
        @clear="refresh(true)"
        @keypress.enter.native="refresh(true)"
      >
        <i
          slot="suffix"
          class="el-input__icon el-icon-search"
          @click="refresh(true)"
        />
      </BaseElInput>

      <BaseElSelect
        v-model="search.status"
        placeholder="選擇活動狀態"
        clearable
        @change="refresh(true)"
        @clear="refresh(true)"
      >
        <BaseElSelectOption
          v-for="option in serialActivityStatusConfig"
          :key="option.value"
          :label="option.label"
          :value="option.value"
        />
      </BaseElSelect>
    </FilterContainer>
    <RewardActivityTable v-loading="loading.table" :tableData="tableData" @refresh="refresh" />
    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="tableDataCount"
      @pageChange="refresh(false)"
    />
  </div>
</template>

<script>
// page - 序號登錄活動紀錄頁
import { defineComponent, onMounted, onActivated } from 'vue'
import PageTitle from '@/components/Title/PageTitle.vue'
import FilterContainer from '@/components/Container/FiltersContainer.vue'
import RewardActivityTable from './components/RewardActivityTable.vue'
import { GetSerialActivity as GetDataAPI, GetSerialActivityCount as GetDataCountAPI } from '@/api/serialActivity'
import { useTable } from '@/use/table'
import { useRouter } from 'vue-router/composables'
import { serialActivityStatusConfig } from '@/config/serialActivity'

export default defineComponent({
  name: 'RewardActivityList',
  components: { PageTitle, FilterContainer, RewardActivityTable },
  setup (props, { emit }) {
    const router = useRouter()
    const {
      shopId, loading, search, extendData, pageStartIndex,
      tableData, tableDataCount, tableOptions,
    } = useTable()

    extendData('search', {
      name: null,
      status: null,
    })

    const getData = async (payload) => {
      const [res, err] = await GetDataAPI(payload)
      if (err) return window.$message.error(err)
      tableData.value = res
    }
    const getDataCount = async (payload) => {
      const [res, err] = await GetDataCountAPI(payload)
      if (err) return window.$message.error(err)
      tableDataCount.value = res.count
    }

    const getTableData = async () => {
      const payload = {
        shopId: shopId.value,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
        name: search.name || undefined,
        status: search.status || undefined,
      }
      await Promise.allSettled([
        getData(payload),
        getDataCount(payload),
      ])
    }

    const refresh = async (search = false) => {
      if (search) tableOptions.page = 1
      loading.table = true
      await getTableData()
      loading.table = false
    }

    onMounted(async () => {
      refresh()
    })
    onActivated(async () => {
      refresh()
    })

    return {
      loading,
      search,
      tableData,
      tableDataCount,
      tableOptions,
      refresh,
      serialActivityStatusConfig,
    }
  },
})
</script>
